export const GREEN = "#04A025";
export const PURPLE = "#4814CC";
export const DARKPURPLE = "#5A438A";
export const YELLOW = "#FFE633";
export const BLACK = "#000";
export const BEIGE = "#F2E7D7";
export const WHITE = "#FFF";
export const PURPLEOPPOSITE = "rgb(170, 208, 9)";
export const GREENOPPOSITE = "rgb(183, 64, 163)";


const COLORS = {
  GREEN,
  PURPLE,
  DARKPURPLE,
  YELLOW,
  BLACK,
  BEIGE,
  WHITE,
  PURPLEOPPOSITE,
  GREENOPPOSITE
};

export default COLORS;
