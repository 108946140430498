import styled from "styled-components";
import { BEIGE, BLACK } from "../../constants/colors";
import { SM, MD } from "../../constants/mediaQueries";


export const FooterSocialMedia = styled.div`
margin: 0.25rem;
 
svg {
  margin: 0 0.5rem;
  width: 1.75rem;
  height: 1.75rem;
}

a {
  display: inline-block;
  margin: 0 0.25rem;
  margin-bottom: 0.5rem;
  color: ${BLACK};
  &:hover {
    color: ${BLACK};
  }
  &:active {
    color: ${BLACK};
  }
}

@media (max-width: ${MD}) {
  order: 1;
}
`;

export const FooterWrapper = styled.div`
  position: relative; 
  z-index: 900;
`;

export const FooterContainer = styled.div`
  padding: 1rem 0.5rem;
  padding-bottom: 2rem;
  margin-bottom: 0;
  cursor: default;
  text-align: center;
  color: ${BLACK};
  background: ${BEIGE};


`;

export const FooterText = styled.div`
  cursor: default;
  text-align: center;
  color: ${BLACK};
  font-weight: 600;

  text-transform: uppercase;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;

  font-size: 20px;
  
  p {
    margin-bottom: 0;
  }

  a {
    text-decoration: none;
    color: ${BLACK};
    &:hover {
      color: ${BLACK};
    }
    &:active {
      color: ${BLACK};
    }
  }

@media (max-width: ${MD}) {
  font-size: 16px;
  flex-direction: column;
  span {
    display: none;
  }
}

`;



export const FooterFade = styled.div`
position: relative;
height: 60px;

::after {
  box-shadow: 0px -122px 22px -86px ${BEIGE} inset;
  -webkit-box-shadow: 0px -122px 22px -86px ${BEIGE} inset;
  -moz-box-shadow: 0px -122px 22px -86px ${BEIGE} inset;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  bottom: -1px;
  width: 100%;    
}

@media (max-width: ${SM}) {
  ::after {
    box-shadow: 0px -122px 22px -86px ${BEIGE} inset;
    -webkit-box-shadow: 0px -122px 22px -86px ${BEIGE} inset;
   -moz-box-shadow: 0px -122px 22px -86px ${BEIGE} inset;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    bottom: -1px;
    width: 100%;    
  }

}
`;