import React, {useEffect, useState} from "react";
import { FooterWrapper, FooterContainer, FooterFade, FooterText, FooterSocialMedia } from "./style";
import { FaYoutube, FaInstagram } from "react-icons/fa";
import sanityClient from "../../client.js";

export const Footer = ({ linkTo }) => {
  const [copyrightText, setCopyrightText] = useState('');
  const [creditsLink, setCreditsLink] = useState('#');
  const [creditsText, setCreditsText] = useState('');


  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "footer-content"]{
          credits_text,
          credits_link,
          copyright
        }`
      )
      .then((data) =>{ 
        setCreditsText(data[0]?.credits_text);
        setCreditsLink(data[0]?.credits_link);
        setCopyrightText(data[0]?.copyright);

      })
      .catch(console.error);
  }, []);

  return (
    <FooterWrapper>
      <FooterFade></FooterFade>
      <FooterContainer>
        <FooterSocialMedia>
          <a href="https://www.instagram.com/takeflightintensive_dublin" target="_blank" rel="noreferrer" aria-label="Instagram"><FaInstagram/></a>
          <a href="https://www.youtube.com/@takeflightintensive1766" target="_blank" rel="noreferrer" aria-label="YouTube"><FaYoutube/></a>
        </FooterSocialMedia>

        <FooterText target="_blank" rel="noreferrer">
          <a href={creditsLink} target="_blank" rel="noreferrer">{creditsText}</a>
          <span>&nbsp;-&nbsp;</span>
          <p>{copyrightText}</p>
        </FooterText>
      </FooterContainer>
    </FooterWrapper>
  );
};
