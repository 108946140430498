import React, { useEffect, useState } from "react";
import { MenuContainer, StyledA, StyledLink, DesktopMenu, TicketsButton } from "./style";
import sanityClient from "../../../client.js";

export const FloatingMenu = (props) => {
  const [ticketsLink, setTicketsLink] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState('');
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  function toggleMenu () {
    setMenuOpen(!menuOpen);
  }


  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "get-tickets"]{
          _id,
          title,
          url,
        }`
      )
      .then((data) => {
        setTicketsLink(data[0]?.url)})
      .catch(console.error);
    
    setActiveLink(window.location.pathname);

    const isTouch = (('ontouchstart' in window) ||
      (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));

    setIsTouchDevice(isTouch);

  }, []);
  

  return (
    <>
    <MenuContainer opened={menuOpen} istouch={isTouchDevice} forceBlend={props.forceBlend}>
      <DesktopMenu color={props.color} opened={menuOpen} istouch={isTouchDevice}>
            <StyledLink to="/" selected={activeLink === "/"}>Home</StyledLink>
            <StyledLink to="/faculty" selected={activeLink === "/faculty"}>Faculty</StyledLink>
            <StyledLink to="/events" selected={activeLink === "/events"}>Upcoming Events</StyledLink>
            <StyledA href={ticketsLink ? ticketsLink : "#"} target="_blank" rel="noreferrer">Get your tickets</StyledA>
            <StyledLink  to="/contact" selected={activeLink === "/contact"}>Contact</StyledLink>
            <h4 onClick={toggleMenu} role="button">{menuOpen ? "Close" : "Menu"}</h4>
      </DesktopMenu>
    </MenuContainer>
    {props.showTicketsButton && !menuOpen && 
        <TicketsButton>
          <a href={ticketsLink ? ticketsLink : "#"} target="_blank" rel="noreferrer">Tickets</a>
        </TicketsButton>
    }
    </>
  );
};
