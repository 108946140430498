import styled from "styled-components";
import { SM, LG } from "../../../constants/mediaQueries";
import { BEIGE, PURPLE, BLACK } from "../../../constants/colors";

import { Link } from "gatsby";

export const TicketsButton = styled.div`
position: fixed;
display: block;
z-index: 2000;

bottom: 30px;
right: 30px;
padding: 0.5rem 2rem;
cursor: pointer;
background: ${PURPLE};
color: ${BEIGE};

font-size: 1.875rem;
font-weight: bold;
line-height: 1.2;
letter-spacing: 0em;
text-transform: uppercase;

border-radius: 50%;

a {
  color: ${BEIGE};
  text-decoration: none;
  &:hover {
    color: ${BEIGE};
  }
  &:active {
    color: ${BEIGE};
  }
}

@media (max-width: ${SM}) {
  right: 20px;
  padding: 0.5rem 1.5rem;

  a {
    font-size: 1.875rem;
  }
}

@media (max-width: ${LG}) {
  mix-blend-mode: difference;
}


`;


const LinkStyle = `
  display: none;
  text-decoration: none;
  text-transform: uppercase;
  font-family: inherit;
  font-weight: bold;
  line-height: 1.2;
  font-size: 1.875rem;
  z-index: 550;
  position: relative;


    &:hover {
      text-decoration: line-through;
    } 
`;


export const StyledA = styled.a`
  ${LinkStyle}

`

export const StyledLink = styled(Link)`
  ${LinkStyle}
  ${props => `
    ${props.mobileonly ? `
    @media (min-width: ${SM}) {
      display: none !important;
    }
    ` : ''}

    ${props.selected ? 'text-decoration: line-through;' : ''}
  `}
`

export const DesktopMenu = styled.div`
  background: ${props => props.color || BEIGE};
  position: relative;
  color: ${props => props.color === PURPLE ? BEIGE : BLACK};
  font-size: 16px;
  line-height: 1.2;
  letter-spacing: 0em;
  text-align: left;
  padding: 0.5rem 3rem;
  border-radius: 50%;
  
  h4 {
    margin: auto;
    font-size: 1.875rem;
    font-weight: bold;
    color: ${props => props.color === PURPLE ? BEIGE : BLACK};
    text-transform: uppercase;
    z-index: 550;
    position: relative;
  }

  a {
      color: ${props => props.color === PURPLE ? BEIGE : BLACK};
    &:hover {
      color: ${props => props.color === PURPLE ? BEIGE : BLACK};
    }
    &:active {
      color: ${props => props.color === PURPLE ? BEIGE : BLACK};
    }
  }

  @media (max-width: ${SM}) {
    padding: 0.5rem 2.5rem;

    h4, a {
      font-size: 1.875rem;
    }
  }

  ${props => props.opened && props.istouch ? `
    border-radius: 0px;
    padding-bottom: 2rem!important;  
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  ` : ''}
`;

export const MenuContainer = styled.div`
  position: fixed;
  bottom: 30px;
  left: 30px;
  display: block;
  z-index: 2000;

  @media (max-width: ${SM}) {
    left: 20px;
  }
  h4 {
    cursor: pointer;
  }



  ${props => props.istouch ? `

    ${props.opened ? `

    bottom: 0;
    top: 0;
    right: 0;
    left: 0!important;

      a {
        display: block;
        margin-left: 30px;
      }

      h4 {
        margin: 0;
        margin-top: 3rem;
        margin-left: 30px;
      }


      @media (max-width: ${SM}) {
        a,h4 {
          margin-left: 20px;
          margin-bottom: 5px;
        }
      }

      mix-blend-mode: none;
    
    ` : `
    @media (max-width: ${LG}) {
      mix-blend-mode: difference;
    }
    ${props.forceBlend ? ' mix-blend-mode: difference;' : '' }
    `}
  
  ` : `

    @media (max-width: ${LG}) {
      mix-blend-mode: difference;
    }
    ${props.forceBlend ? ' mix-blend-mode: difference;' : '' }
    
    &:hover {
      h4 {
        display: none;
      }

      a {
        display: block;
      }
    }

    &:hover {
      div {
        border-radius: 25px; 
      }
    }
  `}


`;


